import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const uat: IPartnerAppSettings = {
  cdnBaseUrl: 'https://fxpsit.azureedge.net/delegation-ux',
  serviceEndPoints: [
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://delegation-api-ppe.azurefd.net/',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://flighting-api-preprod.azurefd.net/',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://delegationplatform-api-ppe-eus.azurewebsites.net/'
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://servicesuat.microsoft.com/'
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/v1.0',
    },
  ],
  delegationServiceBaseUri: 'https://servicesuat.microsoft.com/',
  graphV1ApiUri: 'https://graph.microsoft.com/v1.0',
  tenantId: 'ES',
  submissionStatusCheckMaxCount: 40,
  submissionStatusCheckPollingInterval: 1000,
  isDelegationSubmissionAllowedInObo: false,
  businessClaimsTenant: 'PS',
  adminClaimsTenant: 'FxpPlatform'
};
