import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const aurorasit: IPartnerAppSettings = {
	cdnBaseUrl: 'https://fxpsit.azureedge.net/delegation-ux',
	serviceEndPoints: [
		{
			clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
			serviceEndPoint: 'https://delegation-api-ppe.azurefd.net/'
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://delegationplatform-api-ppe-eus.azurewebsites.net'
    },
		{
			clientId: '00000003-0000-0000-c000-000000000000',
			serviceEndPoint: 'https://graph.microsoft.com/v1.0'
		}
	],
	delegationServiceBaseUri: 'https://delegation-api-ppe.azurefd.net/',
	graphV1ApiUri: 'https://graph.microsoft.com/v1.0',
	tenantId: 'DEMO_TEN_1',
	submissionStatusCheckMaxCount: 40,
	submissionStatusCheckPollingInterval: 1000,
	isDelegationSubmissionAllowedInObo: true,
	businessClaimsTenant: 'PS',
	adminClaimsTenant: 'FxPPlatform'
};
