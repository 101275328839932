import {
  IAppService,
  IStateConfig,
  IServiceEndPoints,
  IPartnerBundle,
  IRouteInfo,
  IFxpAppContext,
} from "@fxp/fxpservices";
import { CommonConstants } from "./app/shared/constants/commonConstants";
import { appSettings } from "./environments/common/appsettingsGenerator";
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class Delegation_routes implements IAppService {
  public getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }
  public getRoutes(userContext: IFxpAppContext): IRouteInfo {
    const DashboardRoute: IStateConfig = {
      name: "delegationdashboard",
      url: "/delegationdashboard",

generatedBundle: '795020909b34-DelegationDashboardModule-Bundle',
      data: {
        headerName: "Delegation Dashboard",
        breadcrumbText: "Delegation Dashboard",
        pageTitle: "Delegation Dashboard",
        style: "icon icon-people",
        partnerTelemetryName: "FXP",
        appNameForTelemetry: `${CommonConstants.DelegationTelemetryBaseName}.Delegation`,
        pageNameForTelemetry: `${CommonConstants.DelegationTelemetryBaseName}.DelegationDashboard`,
      },
    };
    const OBORoute: IStateConfig = {
      name: "delegationadmin",
      url: "/delegationadmin",

generatedBundle: '795020909b34-DelegationDashboardModule-Bundle',
      data: {
        headerName: "Delegation Administration",
        breadcrumbText: "Delegation OBO",
        pageTitle: "Delegation Administration",
        style: "icon icon-people",
        partnerTelemetryName: "FXP",
        appNameForTelemetry: `${CommonConstants.DelegationTelemetryBaseName}.Delegation`,
        pageNameForTelemetry: `${CommonConstants.DelegationTelemetryBaseName}.DelegationAdministration`,
      },
    };
    const routeInfo: IRouteInfo = {
      applicationName: "DelegationPlatformApp",
      sharedBundles: [],
      routes: [DashboardRoute, OBORoute],
    };
    return routeInfo;
  }
  public getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    let bundlesData = [
      {
        name: "DelegationPlatformApp-Bundle",
        files: [
          `${baseUrl}/vendor.bundle.js`,
          `${baseUrl}/styles.bundle.js`,
          `${baseUrl}/main.bundle.js`,
        ],
        sequentialLoading: true,
      },
    ];
    return bundlesData;
  }

 public getGeneratedBundles(){
 let currentScriptUrl = document.currentScript['src']; 
let baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/')); 
 return [
{
                name: '795020909b34-DelegationDashboardModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/DelegationDashboardModule.vendor.bundle.js',
                    baseUrl + '/DelegationDashboardModule.bundle.js'
                ],
                sequentialLoading: true           
            },

]
 
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(Delegation_routes, 'DelegationPlatformApp');
