import { Environment } from '@fxp/fxpservices';
import { dev } from "../configs/appSettings.dev";
import { sit } from "../configs/appSettings.sit";
import { uat } from "../configs/appSettings.uat";
import { prod } from "../configs/appSettings.prod";
import { aurorasit } from "../configs/appSettings.aurorasit";
import { IPartnerAppSettings } from "../interface/IPartnerAppSettings";

export const appSettings = (): IPartnerAppSettings => {
  let appSetting: IPartnerAppSettings;
  let environmentName =
    window &&
    window["FxpAppSettings"] &&
    window["FxpAppSettings"].EnvironmentName
      ? window["FxpAppSettings"].EnvironmentName
      : Environment.Dev;
  switch (environmentName) {
    case Environment.Dev:
      appSetting = dev;
      break;
    case Environment.Sit:
      appSetting = sit;
      break;
    case Environment.Uat:
      appSetting = uat;
      break;
    case Environment.AuroraSit:
      appSetting = aurorasit;
      break;
    case Environment.Prod:
      appSetting = prod;
      break;
    default:
      appSetting = dev;
      break;
  }

  return appSetting;
};
