import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: 'https://fxpprodcdn.azureedge.net/delegation-ux',
  serviceEndPoints: [
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://esxp.microsoft.com/',
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://delegation-api-prod.azurefd.net/',
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://delegation-api-prod-eus.azurewebsites.net'
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://delegation-api-prod-wus.azurewebsites.net'
    },
    {
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9',
      serviceEndPoint: 'https://flighting-api-prod.azurefd.net'
    },
    {
      clientId: '00000003-0000-0000-c000-000000000000',
      serviceEndPoint: 'https://graph.microsoft.com/v1.0',
    },
  ],
  delegationServiceBaseUri: 'https://esxp.microsoft.com/',
  graphV1ApiUri: 'https://graph.microsoft.com/v1.0',
  tenantId: 'ES',
  submissionStatusCheckMaxCount: 40,
  submissionStatusCheckPollingInterval: 1000,
  isDelegationSubmissionAllowedInObo: false,
  businessClaimsTenant: 'PS',
  adminClaimsTenant: 'FxpPlatform'
};
